import { getUser, isDefaultUser, removeCookie } from "./utils";

export async function getClubs(fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/clubs`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response); // JSON response  
    } else if(this.status == 401) {
      removeCookie("id");
      location.reload();
    }
  };
  xmlHttp.send( null );
}

export async function getClubsWithStats(ordering, fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json';
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/clubsWithStats`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      var output = this.response.clubsWithStats;
      if(ordering == 2){
        output.sort(function(first, second){return second.stats.averageGoodDistance - first.stats.averageGoodDistance})
      } else if(ordering == 3){
        output.sort(function(first, second){return second.stats.successRate - first.stats.successRate})
      } else if(ordering == 4){
        output.sort(function(first, second){return second.stats.rightOfflineBias - first.stats.rightOfflineBias})
      } else if(ordering == 5){
        output.sort(function(first, second){return second.stats.leftOfflineBias - first.stats.leftOfflineBias})
      } else {
        output.sort(function(first, second){return first.club.displayOrder - second.club.displayOrder})
      }
      fn({clubsWithStats: output, units: this.response.units}); // JSON response  
    } else if(this.status == 401) {
      removeCookie("id");
      location.reload();
    }
  };
  xmlHttp.send( null );
}

export async function getSessionComapare(fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json';
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/sessionCompare`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response.sessions); // JSON response  
    } else if(this.status == 401) {
      removeCookie("id");
      location.reload();
    }
  };
  xmlHttp.send( null );
}

export async function getSessionsLeaderboard(fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json';
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/sessionsLeaderboard`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response); // JSON response  
    } else if(this.status == 401) {
      removeCookie("id");
      location.reload();
    }
  };
  xmlHttp.send( null );
}

export async function setUserRecency(recency, fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "PUT", `https://api.my-golf-insights.com/shot-recency/${recency}`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(null);
    } else {
      fn(this.response);
    }
  };
  xmlHttp.onerror = function(e) {
    fn("Failed to update shot recency");
  };
  xmlHttp.send( null );
}

export async function emailOptOut(email, success, failure) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "PUT", `https://api.my-golf-insights.com/opt-out-emails/${email}`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 204) {
      success();
    } else {
      failure();
    }
  };
  xmlHttp.onerror = function(e) {
    failure();
  };
  xmlHttp.send( null );
}

export async function setMarketingEmailSetting(isOptIn, success, failure) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "PUT", `https://api.my-golf-insights.com/user/marketing-emails/${isOptIn}`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 204) {
      success();
    } else {
      failure();
    }
  };
  xmlHttp.onerror = function(e) {
    failure();
  };
  xmlHttp.send( null );
}

export async function setUserUnitPreference(unitPreference, fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "PUT", `https://api.my-golf-insights.com/user/unit-preference/${unitPreference}`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(null);
    } else {
      fn(this.response);
    }
  };
  xmlHttp.onerror = function(e) {
    fn("Failed to update unit preference");
  };
  xmlHttp.send( null );
}

export async function getUserSettings(fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/user/settings`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response);
    } else if(this.status == 401) {
      removeCookie("id");
      location.reload();
    }
  };
  xmlHttp.send( null );
}

export async function pullClubs(fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/top-tracer-clubs`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response); // JSON response  
    } else {
      fn(null, this.status == 401 || isDefaultUser());
    }
  };
  xmlHttp.onerror = function(e) {
    fn(null, isDefaultUser());
  };
  xmlHttp.send( null );
}

export async function pullShots(fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json'; 
  xmlHttp.open( "GET", `https://api.my-golf-insights.com/top-tracer-shots`); 
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + getUser());
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response); // JSON response  
    } else {
      fn(null, this.status == 401 || isDefaultUser());
    }
  };
  xmlHttp.onerror = function(e) {
    fn(null, isDefaultUser());
  };
  xmlHttp.send( null );
}


export async function topTracerLogin(username, password, marketingValue, fn) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.responseType = 'json';
  // xmlHttp.setRequestHeader('Content-type', 'application/json'); 
  xmlHttp.open( "POST", `https://api.my-golf-insights.com/top-tracer-login`); 
  xmlHttp.onload = function(e) {
    if (this.status == 200) {
      fn(this.response); // JSON response  
    } else {
      fn(null);
    }
  };
  xmlHttp.onerror = function(e) {
      fn(null);
  };
  xmlHttp.send(`{"loginRequestBody": {"email":"${username}","password":"${password}"},"marketingEmails":${marketingValue}}`);
}